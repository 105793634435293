import { Card, CardContent, CardMedia, Grid, Hidden, ListItem, makeStyles, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import { ga4Events } from '../../../../utils';
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
        rmBox: {
            boxShadow: 'none',
            position: 'relative'
        },
        rmpaddinglt: {
            paddingLeft: 0
        },
        brandName: {
            // position: 'absolute', 
            // marginTop: '-22px', 
            // marginLeft: '10px',
            paddingTop: '8px',
            color: '#333333', 
            fontWeight: 700, 
            fontSize: '14px'
        }
  }));

const Categories = ({ itemToShow, clickedUser}) => {
   
    const classes = useStyles();
    const [imageError, setImageError] = useState(false);
   
    let handleGA = (item, e) => {
      
      
      localStorage.setItem('categoryContainer',JSON.stringify(e.target.dataset.cat))
        // ga4Events({
        //     action: `catalog_view_${item.name}_click`,
        //     category: "Listing",
        //     subcategory_id: item && item.sub_category && item.sub_category[0]?._id,
        //     subcategory_name: item && item.sub_category && item.sub_category[0]?.name,
        // });
    };

    return (
        <Grid container spacing={2} className="parts-view">
            {itemToShow?.map((brand, index) => (
                <Grid item xs={6} md={2} lg={2} key={brand.name}>
                    <NavLink to={`/spare-parts/${brand.name}`} data-cat ='categoryContainer'
                        onClick={(e) => {
                            handleGA(brand, e);
                        }}
                    >
                        <Card sx={{ maxWidth: 345 }} style={{textAlign:'center'}}>
                            <CardMedia data-cat ='categoryContainer'
                                component="img"
                                height="170px"  width="170px"
                                src={brand && brand?.brand_img ? brand?.brand_img : "/assets/img/byparts.jpg" }
                                // onError={handleImageError}
                                onClick={clickedUser} 
                            />
                            <Typography style={{height:'40px'}}
                                className={classes.brandName} data-cat ='categoryContainer'>
                                {brand.name} 
                            </Typography>
                            <CardContent className={classes.rmpaddinglt} data-cat ='categoryContainer'>
                                {brand?.subCategories && brand.subCategories.length
                                    ? brand.subCategories.map((sub, ind) => {
                                        let activeCategory = brand.name
                                            ?.toLowerCase()
                                            ?.replaceAll(" ", "-")
                                            ?.replaceAll("/", "-");
                                        let subCat = sub?.name
                                            ?.toLowerCase()
                                            ?.replaceAll(" ", "-")
                                            ?.replaceAll("/", "-");
                                        return (
                                            <NavLink key={`${brand.name}-${subCat}`}
                                                to={`/spare-parts/${activeCategory}/${subCat}`}
                                                onClick={(e) => {
                                                    handleGA(brand, e);
                                                }} style={{ color: '#333', padding: 0 }}
                                            >
                                                <Hidden xsDown={true}>
                                                    <ListItem style={{ padding: 0, marginBottom: '2px', fontSize: '12px', fontWeight: 400 }}>{sub.name}</ListItem>
                                                </Hidden>
                                            </NavLink>
                                        );
                                    })
                                    : null}
                            </CardContent>
                        </Card>
                    </NavLink>
                </Grid>
            ))}
        </Grid>
    )
}

export default Categories
