import React from 'react'
import ThumbnailCard from '../../card/ThumbnailCard';

const CollectionGrid = ({ section }) => {
    return (
        <div className='thumbnail-list mt-4'>
            {section.sectionData?.ids?.length && section.sectionData?.ids?.map((collection) => {
                return (
                    <ThumbnailCard
                        id={collection?._id}
                        slug={collection?.slug}
                        thumbnail={collection?.thumbnail}
                        title={collection?.title}
                        section={section}
                    />
                );
            })}
        </div>
    )
}

export default CollectionGrid
