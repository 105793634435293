import React, { Fragment, Component, useState, useEffect} from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { 
  Button,  
  Stack,
} from "@mui/material";
import { ArrowForward} from "@material-ui/icons";
import { FaRegCommentDots } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import Loader from "../content/element/Loader";
import { getBannerList } from "../../Store/action/listingActions";
import { ga4Events, handleBannerEvents } from "../../utils";
import BannerSlider from "../content/element/Collections/BannerSlider";
import Section from "../content/element/Collections";
import SKUSlider from "../content/element/Collections/SKUSlider";
import ThumbnailGrid from "../content/element/Collections/ThumbnailGrid/index.jsx";
import ThumbnailSlider from "../content/element/Collections/ThumbnailSlider";
import LoginModal from "../content/Login/LoginModal";
import Catalog from "../content/element/Catalog/index.jsx";
import Hidden from '@material-ui/core/Hidden';
import { Grid } from "@mui/material";
import Leaderboard from "../content/element/leaderboard";
import Categories from "../content/element/Catalog/Category.jsx";
import { useSelector } from "react-redux";
import { Padding } from "@mui/icons-material";
import Purchase from "../content/element/Purchase";
const Index = (props) => {
  const [redirectURL, setRedirectURL] = useState("");
  const [isModalOpen, setIsModalOpen] = useState("");
  const [loading, setLoading] = useState(false);
 
  const [itemToShowPart, setItemToShowPart] = useState([]);
  const [viewAl, setViewAl] = useState(false);

  const [viewAlShow, setViewAlShow] = useState(false); 
  const [partsDat, setPartsDat] = useState([])
  //toggle for login
  const toggle = (e, banner) => {
    setIsModalOpen(!isModalOpen);
    setRedirectURL(banner);
  }
  const toggleForLogin = () => {
    setIsModalOpen(false);
  }

  const { homepagesection } = props; 
  const { allCategories } = useSelector((state) => state.category); 
  const currentUser = JSON.parse(localStorage.getItem("login"));
  
  useEffect(() => {

    const partsData= allCategories?.map((obj) => {
      return {
        brand_img: obj.imgUrl,
        name: obj.name,
      };
    });
   
    setPartsDat(partsData)
    setItemToShowPart(partsData.slice(0, 12));
    partsData.length > 12 ? setViewAl(true) : setViewAl(false);
    partsData.length > 12 ? setViewAlShow(true) : setViewAlShow(false);


}, [
  allCategories
]);

  //For GTM Tracking

   useEffect(() => {
      if(currentUser){
      setTimeout(function() {

      window.dataLayer = window.dataLayer || [];
   let allSectionCount = document.querySelectorAll('.section-count');
   console.log(`allSectionCount:`, allSectionCount, allSectionCount.length);
   const allSectionData = [];
      allSectionCount.forEach((section, index)=> {
      if(section){
        let section_name = section.querySelector('.section_name').innerHTML;
        let section_type = section.querySelector('.section_type').innerHTML;
    
      allSectionData.push({
        section_name,
        section_type
        })
      }
     
    })
   
      window.dataLayer.push({
        event: 'userLogin',
        user_id :currentUser.ecvId,
        kyc_status : currentUser.KYCStatus,
        status : currentUser.status,
        role : currentUser.role,
        vat_registered : currentUser.details.vat_registration_no? true :false,
        emirate : currentUser.details.emirate,
        companyType: currentUser.details.garage_type,
        page_type:'HomePage',
        section_count:allSectionCount.length,
        sections: allSectionData,
      })

}, 5000);  
const pathname = window.location.href; //this gives me current Url
localStorage.setItem('pageUrl',JSON.stringify(pathname))
 }
   },[]);
  let updateItemList = () => {
   
    if (allCategories) {
      if (itemToShowPart.length == 12) {
        setItemToShowPart(
          allCategories.map((obj) => {
            return {
              brand_img: obj.imgUrl,
              name: obj.name,
            };
          })
        );
        setViewAl(false);
      } else {
        setItemToShowPart(
          allCategories
            .map((obj) => {
              return {
                brand_img: obj.imgUrl,
                name: obj.name,
              };
            }).slice(0, 12)
        );
        setViewAl(true);
      }
    }
   
  };
  if (loading) return <Loader />;
  let slider = props?.list?.bannersList?.filter((banner) => (banner?.archived === false) && banner.category === "billboard_home_banner")?.sort((a, b) => a.position - b.position)
  let left_leaderboard_home_page = props?.list?.bannersList?.find((banner) => (banner?.archived === false) && banner.category === "left_leaderboard_home_page")
  let right_leaderboard_home_page = props?.list?.bannersList?.find((banner) => (banner?.archived === false) && banner.category === "right_leaderboard_home_page")

  const handleGaEvent = (banner) => {
    const action = banner?.collectionId?._id ? `collection_home_banner_click` : `home_page_banner_click`
    handleBannerEvents(action, banner);
  }  

  return (
    <Fragment>

      <div className='container-fluid catalog-block mb-4' >


        <Hidden xsDown smDown>

          <div className='col-md-2 banner-dimentions' >

            {left_leaderboard_home_page && (
              <Leaderboard banner={left_leaderboard_home_page} position="left" />
            )
            }
          </div>
        </Hidden>
        {/* <div className='col-md-8 catalog-container' > */}
        <div className='col-lg-8 col-md-12 middle-content'  >
          {/* Catalogs start */}
          {
            props?.brand?.isBrandLoading ||
              props.brand?.brands?.length !== 0 ?
              <section className='browse-category-wrapper makes-category section-count'>
                <div className='removepd'>
                  <div className='section-title'>
                    <div className='d-flex align-items-center'>
                      <div>
                        <h3 className="about_us_title section_name">
                          Catalog
                        </h3>
                      </div>
               <p style={{display:'none'}} className="section_type">Catalog</p>
                    </div>
                  </div>
                  {/* <BrowseByMake /> */}
                  <Catalog clickedUser={(e) => !currentUser && toggle(e)} />
                </div>
              </section> : null
          }
          {/* Catalogs end */}
          <div className="carousel-item-image section-count">
            <div style={{ display: "none" }}>
                        <h3 className="about_us_title section_name">
                        carousel
                        </h3>
                      </div>
             <p style={{display:'none'}} className="section_type">Image Carousel</p>
            <div
              id="homepage-carousel"
              className="carousel carousel-div slide"
              data-ride="carousel"
            >
              <ol className="carousel-indicators">
                {slider?.map((banner, index) => {
                  return <li
                    key={index}
                    data-target="#homepage-carousel"
                    data-slide-to={index}
                    className={index === 0 ? "active" : ""}
                  ></li>
                })}
              </ol>
              <div className="carousel-inner">
                {props?.list?.bannersList ? (
                  slider?.map((banner, index) => {
                    return (
                      <>
                        <div
                          className={
                            index === 0
                              ? `carousel-item active `
                              : `carousel-item`
                          }

                        >
                          <a onClick={(e) => {
                            handleGaEvent(banner);
                            !currentUser && toggle(e, banner?.banner_link)
                          }

                          }
                            href={(currentUser && banner)?.banner_link || '#'}
                            style={{ textAlign: "center" }}
                          >
                            <img
                              src={banner?.banner_upload}
                              className="d-flex w-100"
                              alt="..."
                            />
                          </a>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <>
                    <div className="carousel-item active">
                      <img
                        src="/assets/img/home-page-banner/home-page-banner-1.jpg"
                        className="d-block w-100"
                        alt="..."

                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src="/assets/img/home-page-banner/home-page-banner-2.jpg"
                        className="d-block w-100"
                        alt="..."

                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src="/assets/img/home-page-banner/home-page-banner-3.jpg"
                        className="d-block w-100"
                        alt="..."

                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src="/assets/img/home-page-banner/home-page-banner-4.jpg"
                        className="d-block w-100"
                        alt="..."

                      />
                    </div>
                  </>
                )}
              </div>
            
            </div>
          </div>
          {
            currentUser && (
              <div className="section-count">
              <p style={{display:'none'}} className="about_us_title section_name">
                        Buy Again
                        </p>
              <p style={{display:'none'}} className="section_type">Product Slider</p>
            <Purchase /></div>
            )
          }

          {/* homepage settings */}

          {
            homepagesection?.homepageSections?.length > 0 && (
              homepagesection?.homepageSections?.map(section => {
                switch (section.sectionType) {
                  case 'banner_slider':
                    return (
                      <Section
                        section={section} 
                         
                      >
                          <div className="section-count">
                          <p style={{display:'none'}} className="section_name">{section.name}</p>
                          <p style={{display:'none'}} className="section_type">{section.sectionType}</p>
                          </div>
                        <BannerSlider
                          datas={section?.sectionData?.ids}
                          section={section}
                        />
                      </Section>
                    );
                  case 'thumbnail_slider':
                    return (
                      <Section
                        section={section} 
                      ><div className="section-count">
                          <p style={{display:'none'}} className="section_name">{section.name}</p>
                          <p style={{display:'none'}} className="section_type">{section.sectionType}</p>
                          </div>
                        <ThumbnailSlider
                          section={section}
                        />
                      </Section>
                    );
                  case 'thumbnail_grid':
                    return (
                      <Section
                        section={section} 
                      ><div className="section-count">
                          <p style={{display:'none'}} className="section_name">{section.name}</p>
                          <p style={{display:'none'}} className="section_type">{section.sectionType}</p>
                          </div>
                        <ThumbnailGrid
                          section={section}
                        />
                      </Section>
                    );
                  case 'product_slider':
                    return (
                       <div className="section-count">
                        <p style={{display:'none'}} className="section_name">{section.name}</p>
                          <p style={{display:'none'}} className="section_type">{section.sectionType}</p>
                      <Section
                        section={section}
                      >  
                        
                          
                        <SKUSlider
                          collection={section?.sectionData?.ids?.[0]}
                          skus={section?.productIds}
                        />
                      </Section>
                  </div>
                    );
                }
             }) 
            )
          
     
}
           <div className='section-title section-count' style={{marginTop: '3rem'}}>
                    <div className='d-flex align-items-center'>
                      <div>
                        <h3 className="about_us_title section_name">
                          Category
                        </h3>
                      </div>
               <p style={{display:'none'}} className="section_type">Category Slider</p>
                    </div>
                  </div>
                  {itemToShowPart &&
        <Categories itemToShow={itemToShowPart} clickedUser={(e) => !currentUser && toggle(e)} />
                  }
        {allCategories && viewAlShow ? (
          <Stack>
            <Button
              variant="text"
              endIcon={<ArrowForward style={{ height: '12px', width: '14px', marginTop: 0 }} />}
              onClick={updateItemList}
              style={{ backgroundColor: 'transparent', color: '#1D71B8', fontSize: '14px', fontWeight: 700 }}
            >
              {viewAl ? `LOAD MORE` : `LOAD LESS`}
            </Button>
          </Stack>
        ) : (
          <></>
        )}
      
        </div>
        <Hidden xsDown smDown>
          <div className='col-md-2  banner-dimentions' >
            {right_leaderboard_home_page && (
              <Leaderboard banner={right_leaderboard_home_page} position="right" />
            )
            }
          </div>
        </Hidden>
      </div>

      {/* Need Any Help start */}
      {/* <Grid container style={{padding: '3rem'}}>
        <Grid item xs={12} md={6} lg={6}>
          <p
            style={{ fontWeight: 500, color: "#1a0451", textAlign: 'center' }}
            className="mt-1 mb-0 mr-2"
          >
            We are always here to help.
          </p>
        </Grid>
        <Grid item xs={12} md={3} lg={3}></Grid>
        <Grid item xs={12} md={3} lg={3} justifyContent="flex-end">
          <div
            style={{
              textAlign: 'center',
              fontWeight: "400",
              backgroundColor: "#1e71b8",
              height: "32px",
              fontWeight: 400
            }}
          >
            <NavLink
              className="nav-link "
              to={"/contact"}
              style={{ color: "white", fontSize: 12, flexWrap: "nowrap", whiteSpace: "nowrap", }}
              onClick={() => ga4Events({ action: "need_help_click", category: "Support" })}
            >
              <FaRegCommentDots size={15} className="mr-2" />
              Need Help? Ask our Support
            </NavLink>
          </div>
        </Grid>
      </Grid> */}
      {/* Need Any Help start */}
    </Fragment>
  );

}
const mapStateToProps = (state) => {
  return {
    list: state.list,
    login: state.login,
    currency: state.currency,
    brand: state.brand,
    homepagesection: state.homepagesection
  };
};

const mapDispatchToProp = (dispatch) => {
  return {
    GetBannerList: () => dispatch(getBannerList()),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProp),
  withTranslation()
)(Index);
